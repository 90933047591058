import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { STORAGE_URL } from "../constant/url";

const Card = (props) => {
  const { created_at, id, name, path_audio, path_glb, path_usdz } = props;

  const [isMobile] = useState({
    Windows: function () {
      return /IEMobile/i.test(navigator.userAgent);
    },
    Android: function () {
      return /Android/i.test(navigator.userAgent);
    },
    BlackBerry: function () {
      return /BlackBerry/i.test(navigator.userAgent);
    },
    iOS: function () {
      return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    },
  });

  useEffect(() => {
    console.log(created_at, id, name, path_audio, path_glb, path_usdz);
    console.log(isMobile.Android());
    console.log(isMobile.iOS()); // eslint-disable-next-line
  }, []);
  return (
    <Grid item xs={12} md={4}>
      {isMobile.iOS() ? (
        <div
          style={{
            position: "relative",
            height: 500,
            width: "100%",
            background: "red",
          }}
        >
          <model-viewer
            id="reality"
            src={`${STORAGE_URL + path_glb}`}
            camera-controls
            alt="Motor Malbec"
            background-color="#ffffff"
          ></model-viewer>
        </div>
      ) : isMobile.Android() ? (
        <div
          style={{
            position: "relative",
            height: 500,
            width: "100%",
            background: "red",
          }}
        >
          <model-viewer
            id="reality"
            src={`${STORAGE_URL + path_glb}`}
            camera-controls
            alt="Motor Malbec"
            background-color="#ffffff"
          ></model-viewer>
        </div>
      ) : (
        <div style={{ height: 500, width: "100%" }}>
          <model-viewer
            id="reality"
            src={`${STORAGE_URL + path_glb}`}
            camera-controls
            alt="Motor Malbec"
            background-color="#ffffff"
          ></model-viewer>
        </div>
      )}
      {isMobile.iOS() ? (
        <a href={`${STORAGE_URL + path_usdz}`}>
          <Button style={{ left: "50%", transform: "translate(-50%,0)" }}>
            View {name}
          </Button>
        </a>
      ) : isMobile.Android() ? (
        <a
          style={{ height: 500, width: "100%" }}
          href={`intent://arvr.google.com/scene-viewer/1.1?file=${
            STORAGE_URL + path_glb
          }&amp;mode=ar_only&amp;title=byTheGarage&amp;#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https%3A%2F%2Fargency.com.ar;end;`}
        >
          <Button style={{ left: "50%", transform: "translate(-50%,0)" }}>
            View {name}
          </Button>
        </a>
      ) : (
        <Button style={{ left: "50%", transform: "translate(-50%,0)" }}>
          {name}
        </Button>
      )}
    </Grid>
  );
};

export default Card;
