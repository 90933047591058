import { useEffect, useState } from "react";
import { SUCCESSFUL } from "../constant/types";
import { STORAGE_URL } from "../constant/url";

export default function useFetch(url) {
  const [data, setData] = useState(null);
  useEffect(() => {
    async function loadData() {
      const response = await fetch(STORAGE_URL + url);
      if (!response.status === SUCCESSFUL) {
        // oups! something went wrong
        return;
      }

      const posts = await response.json();
      setData(posts.response);
    }

    loadData();
  }, [url]);
  return data;
}
