import React from "react";
import { Grid } from "@mui/material";
import styled from "@emotion/styled";
import CustomPanel from "./components/CustomPanel";
import useFetch from "./hooks/useFetch";
import Card from "./components/Card";

const Container = styled("div")({
  minHeight: "100vh",
  position: "relative",
  minWidth: "100%",
  backgroundColor: "#FFFFFF",
});

const BackgroundForm = styled("div")({
  boxSizing: "border-box",
  position: "relative",
  height: "auto",
  maxWidth: "1088px",
  width: "calc(100% - ",
  backgroundColor: "#E0EFFF",
  display: "flex",
  flexDirection: "column",
  padding: "20px",
  borderRadius: "20px",
  marginTop: "50px",
});

function App() {
  const data = useFetch("/api/admin/models_ar");

  return (
    <Container>
      <BackgroundForm>
        <Grid container>
          <CustomPanel />
        </Grid>
        {/* <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <ArchiveList />
        </Grid> */}
      </BackgroundForm>

      <Grid container>
        {data &&
          data.models.map((el, index) => (
            <Card
              key={index}
              name={el.name}
              path_audio={el.path_audio}
              path_glb={el.path_glb}
              path_usdz={el.path_usdz}
            />
          ))}
      </Grid>
    </Container>
  );
}

export default App;
