import { SUCCESSFUL } from "../constant/types";
import { URL_BASE } from "../constant/url";
import axios from "axios";

export async function createModelar(data) {
  let url = URL_BASE + `models_ar`;
  try {
    const res = await axios.post(url, data);
    if (res.data.status === SUCCESSFUL)
      return Promise.resolve({
        type: "SUCCESSFUL",
        response: res.data.response,
      });
    else return Promise.resolve({ type: "ERROR" });
  } catch (err) {
    return Promise.resolve({ type: "ERROR" });
  }
}

export async function uploadFile(data, modelar_id, type) {
  let url =
    URL_BASE +
    `/files/model/${modelar_id}/${type}?timestamp=${new Date().getTime()}`;
  try {
    const res = await axios.post(url, data);
    if (res.data.status === SUCCESSFUL)
      return Promise.resolve({
        type: "SUCCESSFUL",
        response: res.data,
      });
    else return Promise.resolve({ type: "ERROR" });
  } catch (err) {
    return Promise.resolve({ type: "ERROR" });
  }
}

export async function getModelsAR() {
  let url = URL_BASE + `models_ar`;
  try {
    const res = await axios.get(url);
    if (res.data.status === SUCCESSFUL)
      return Promise.resolve({
        type: "SUCCESSFUL",
        response: res.data,
      });
    else return Promise.resolve({ type: "ERROR" });
  } catch (err) {
    return Promise.resolve({ type: "ERROR" });
  }
}
