import React, { useState } from "react";
import styled from "@emotion/styled";
import Paper from "@mui/material/Paper";
import Collapse from "@mui/material/Collapse";
import { Dialog, DialogTitle, Grid } from "@mui/material";
import InputPdfs from "./InputPdfs";
import { createModelar, uploadFile } from "./service";
import { SUCCESSFUL } from "../constant/types";
const BotonAgregar = styled("button")({
  width: "90%",
  maxWidth: "200px",
  height: "45px",
  lineHeight: "21px",
  letterSpacing: "1.76px",
  backgroundColor: "#FFFFFF !important",
  color: "#DAD1C9",
  textAlign: "center",
  position: "relative",
  borderColor: "#DAD1C9",
  borderRadius: "15px",
  border: "1px solid",
  justifyContent: "center",
  display: "flex",
  flexDirection: "row",
  margin: "10px",
  "&:hover": {
    transition: "all 0.7s ease-in-out",
    backgroundColor: "#37425D !important",
  },
});

const PaperItem = styled(Paper)({
  backgroundColor: "#FFFFFF",
  width: "100%",
  padding: 20,
  borderRadius: "15px",
  boxSizing: "border-box",
});

const Text = styled("p")({
  fontFamily: "Europa-Light",
  fontSize: "14px",
  padding: 0,
  lineHeight: "18px",
  color: "#5D502D",
  width: "100%",
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  justifyContent: "center",
});

const ContainerInput = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  position: "relative",
  margin: "10px 0px",
  width: "100%",
  boxSizing: "border-box",
});

const CustomButton = ({ onClick, label, style }) => {
  return (
    <BotonAgregar onClick={onClick} style={style}>
      <Text>{label}</Text>
    </BotonAgregar>
  );
};

export default function CustomPanel() {
  const [checked, setChecked] = useState(false);
  const [name, setName] = useState("");
  const [usdz, setUsdz] = useState(null);
  const [glb, setGlb] = useState(null);
  const [state, setState] = useState(null);
  const handleChange = () => {
    setChecked((prev) => !prev);
  };
  const handleCreate = async () => {
    setState(1);
    if (name) {
      let res = await createModelar({ name });
      console.log(res.response);
      if (res.type === SUCCESSFUL) {
        await updateFile(usdz, res.response.modelar_id, "usdz");
        setState(2);
        await updateFile(glb, res.response.modelar_id, "glb");
        setState(3);
      }
    }
  };
  const updateFile = async (state, modelar_id, type) => {
    const res = await uploadFile(state, modelar_id, type);
    console.log(res);
  };
  return (
    <div>
      <Collapse in={!checked}>
        <Grid container direction="row">
          <CustomButton onClick={handleChange} label={"Agregar nuevo"} />
        </Grid>
      </Collapse>
      <div>
        <Collapse in={checked}>
          <PaperItem elevation={0}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="center"
              >
                <ContainerInput>
                  <h3 style={{ margin: "0px" }}>Archivos</h3>
                  <input
                    type="text"
                    id={`input-name`}
                    name={`input-name`}
                    placeholder="nombre-archivo"
                    style={{
                      width: "200px",
                      height: "20px",
                    }}
                    onChange={(e) => setName(e.target.value)}
                  />
                </ContainerInput>
                <InputPdfs type={".usdz"} setFile={setUsdz} />
                <InputPdfs type={".glb"} setFile={setGlb} />
              </Grid>

              <Grid item xs={6} md={6}>
                <CustomButton onClick={handleCreate} label={"Crear"} />
              </Grid>
              <Grid item xs={6} md={6}>
                <CustomButton
                  style={{}}
                  onClick={() => setChecked(false)}
                  label="Cancelar"
                />
              </Grid>
            </Grid>
          </PaperItem>
        </Collapse>
      </div>
      {state && (
        <Dialog open={true}>
          <DialogTitle>subiendo {state}/2</DialogTitle>
        </Dialog>
      )}
    </div>
  );
}
